<template>
  <div>
    <Confirmation
      v-if="confirmation.show"
      :title="confirmation.title"
      :message="confirmation.message"
    ></Confirmation>
    <b-container v-else-if="tiers" fluid class="bv-example-row">
      <b-row class="h-100" style="background: #f4f6f9">
        <b-col 
          order="2"
          order-lg="1"
          md="12"
          lg="7"
          class="align-self-center py-5 "
        >
          <b-row align-h="center">
            <b-col md="11" lg="10" xl="8">
              <b-row class="d-flex justify-content-center headline-entite">
                <b-col cols="6">
                  <div v-if="tiers.type == 'PP'">
                    <i class="fas fa-user icon-entite float-left mr-1"></i>
                    <h4>
                      {{ tiers.signataire.prenom + " " + tiers.signataire.nom }}
                    </h4>
                    <p>{{ tiers.signataire.email }}</p>
                  </div>
                  <div v-else>
                    <i class="fas fa-landmark icon-entite float-left mr-1"></i>
                    <h4>
                      {{
                        tiers.hierarchie.immatriculation.raisonSociale.nom1 ||
                        tiers.hierarchie.immatriculation.raisonSociale.nom2
                      }}
                    </h4>
                    <p v-if="tiers.hierarchie.immatriculation.siret">
                      SIRET {{ tiers.hierarchie.immatriculation.siret }}
                    </p>
                    <p v-else-if="tiers.hierarchie.immatriculation.rna">
                      RNA {{ tiers.hierarchie.immatriculation.rna }}
                    </p>
                  </div>
                </b-col>
                <b-col cols="6" class="text-right">
                  <div v-if="tiers.type != 'PP'">
                    <p>
                      {{ tiers.signataire.prenom + " " + tiers.signataire.nom }}
                    </p>
                    <p>Représentant légal</p>
                  </div>
                </b-col>
              </b-row>
              <b-row class="d-flex justify-content-center">
                <b-col cols="12">
                  <h2>Envoi de documents</h2>
                  <p>
                    Veuillez renseigner le formulaire suivant en y déposant les
                    documents justificatifs demandés.
                  </p>
                  <p>
                    Formats acceptés : PDF, JPG, PNG, TIFF<br />
                    Taille maximum : 10 Mo
                  </p>
                  <form method="POST" @submit.prevent="processForm">
                    <b-row cols ="2" cols-sm="3" cols-xl="4">
                      <b-col
                        v-for="(dropzone, index) in dropzones"
                        :key="index"
                      >
                        <vue-dropzone
                          ref="dropzone"
                          :id="'dropzone-' + index"
                          :options="dropzone.dzOptions"
                          :useCustomSlot="true"
                          :includeStyling="true"
                          v-on:vdropzone-success="uploadSuccess"
                          v-on:vdropzone-error="uploadError"
                          v-on:vdropzone-removed-file="fileRemoved"
                        >
                          <div class="dropzone-add">
                            <i class="fas fa-plus"></i>
                          </div>
                        </vue-dropzone>
                        <p>
                          {{ dropzone.label
                          }}<span v-if="dropzone.required" class="required"
                            >*</span
                          >
                        </p>
                      </b-col>
                    </b-row>
                    <b-row align-h="end">
                      <b-col cols="5">
                        <span
                          tabindex="0"
                          v-b-tooltip.top
                          :title="
                            submitBtnDisabled
                              ? 'Veuillez renseigner les fichiers indiqués'
                              : 'Vos fichiers sont prêts à être envoyés !'
                          "
                        >
                          <b-button
                            v-if="submitBtnLoading"
                            class="btn-submit-files mt-4"
                            variant="primary"
                            size="lg"
                            disabled
                          >
                            <b-spinner small></b-spinner>
                            Chargement...
                          </b-button>
                          <b-button
                            v-else
                            class="btn-submit-files mt-4"
                            type="submit"
                            variant="primary"
                            size="lg"
                            :disabled="submitBtnDisabled"
                            >Envoyer</b-button
                          >
                        </span>
                      </b-col>
                    </b-row>
                  </form>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          order="1"
          order-lg="2"
          md="12"
          lg="5"
          class="text-center p-0 m-0"
          style="min-height: 100vh; background: #1f4391; position: relative"
        >
          <h2 class="page-title">
            <span class="bar-gradient bar-gradient-left"></span>
            <span style="color: #ffffff">SECURACT</span>
            <span class="bar-gradient bar-gradient-right"></span>
          </h2>

          <div class="centered">
            <b-img
              fluid
              src="@/assets/images/commissaires-de-justice.png"
              alt="Commisaires de Justice"
              class="m-2"
            />
            <!-- <p class="subtitle">Espace de téléversement sécurisé</p> -->
            <div class="text-left text-white py-5 px-4">
              <p class="subtitle">
                Signification des actes d'Huissiers de Justice par voie dématérialisée - Prise de consentement
              </p>
              
              <p>
                {{ tiers.signataire.civilite }} {{ tiers.signataire.prenom }} {{ tiers.signataire.nom }}
              </p>
              <p>
                Me {{ tiers.huissier.prenom }} {{ tiers.huissier.nom }} Huissier de Justice exerçant en l'office {{ tiers.huissier.raisonSocialeEtudeHdj }} domiciliée au {{ tiers.huissier.adresseOffice }} vous a présenté la signification dématérialisée des actes d'Huissiers de Justice, pour laquelle vous avez donnez un accord de principe.
              </p>
              <p>
                Pour confirmer cet engagement, pourriez-vous lui transmettre les pièces justificatives nécessaires, conformément aux dispositions légales : Décret n° 2012-366 du 15 mars 2012 relatif à la signification des actes d'huissier de justice par voie électronique et aux notifications internationales.
              </p>
              <p>
                Votre consentement prendra effet à l'acceptation des conditions générales d'utilisation qui vous seront présentées dès votre première connexion à votre espace SECURACT <a class="text-white" href="https://www.securact-huissier.fr/" target="_blank">https://www.securact-huissier.fr/</a> (A noter que celui-ci ne sera accessible que lorsque la prise de consentement sera finalisée.)
              </p>
            </div>
          </div>

          <div class="footer">
            <p><a href="#" @click.prevent="modalHelp = true">Besoin d'aide ?</a></p>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-modal v-model="modalHelp" title="Aide" size="lg" scrollable ok-only>
      <h2>NOTICE EXPLICATIVE</h2>
      <h3 class="my-2">PIECES JUSTIFICATIVES REQUISES</h3>
      <p><strong>Si vous êtes un particulier, vous devez obligatoirement joindre au présent formulaire :</strong></p>
      <ul>
        <li>
          Une copie recto/verso de votre pièce d'identité en cours de validité :
          <ul>
            <li>Carte nationale d'identité</li>
            <li>ou Passeport</li>
            <li>ou Titre de séjour</li>
          </ul>
        </li>
        <li>
          Un justificatif de domicile :
          <ul>
            <li>Titre de propriété</li>
            <li>ou Quittance de loyer de moins de trois mois</li>
            <li>ou Facture (de gaz, d'électricité, de téléphone... ) de moins de trois mois</li>
          </ul>
        </li>
      </ul>

      <p><strong>Si vous représenter une personne morale, vous devez obligatoirement joindre au présent formulaire :</strong></p>

      <ul>
        <li>
          Une copie recto/verso de votre pièce d'identité en cours de validité :
          <ul>
            <li>Carte nationale d'identité</li>
            <li>ou Passeport</li>
            <li>ou Titre de séjour</li>
          </ul>
        </li>
        <li>
          Un justificatif d'immatriculation :
          <ul>
            <li>
              Pour les personnes morales immatriculées au registre du commerce et des sociétés :
              <ul>
                <li>Extrait Kbis ou Lbis original du registre du commerce et des sociétés de moins de trois mois</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      
      <h3 class="my-2">REGISTRE NATIONAL DES CONSENTEMENTS A LA SIGNIFICATION DEMATERIALISEE DES ACTES D'HUISSIER DE JUSTICE</h3>
        
      <ul>
        <li>
          Pour les autres :
          <ul>
            <li>Extrait original d'immatriculation au répertoire des métiers de moins de trois mois</li>
            <li>ou Journal d'annonces légales de mois de deux ans mentionnant le nom du représentant légal et l'adresse du siège de la personne morale</li>
            <li>ou Statuts ou toute autre pièce justificative attestant de l'existence légale de la personne morale et faisant apparaître le nom de son représentant légal, l'adresse de son siège, ainsi que la preuve de la déclaration auprès des organismes compétents</li>
          </ul>
        </li>
        <li>
          Un justificatif de domiciliation :
          <ul>
            <li>Titre de propriété</li>
            <li>ou Quittance de loyer de moins de trois mois</li>
            <li>ou Facture (de gaz, d'électricité, de téléphone... ) de moins de trois mois</li>
          </ul>
        </li>
        <li>
          Une attestation d'engagement (Seulement si l'auteur n'est pas le représentant légal) :
          <ul>
            <li>Un document émanant du représentant légal ou de l'organe compétent attestant de la capacité de l'auteur de la déclaration à engager la personne morale</li>
          </ul>
        </li>
      </ul>
    </b-modal>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import axios from "axios";
import Confirmation from "./Confirmation.vue";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import {
  BContainer,
  BRow,
  BCol,
  BImg,
  BButton,
  BSpinner,
  BModal,
  VBTooltip,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";

export default {
  components: {
    vueDropzone: vue2Dropzone,
    BContainer,
    BRow,
    BCol,
    BImg,
    BButton,
    BSpinner,
    BModal,
    Confirmation,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      token: null,
      confirmation: {
        show: false,
        title: "Documents envoyés",
        message: "Vos pièces justificatives ont bien été envoyées, merci !",
      },
      submitBtnDisabled: true,
      submitBtnLoading: false,
      modalHelp: false,
      tiers: null,
      dropzones: [],
    };
  },
  created() {
    this.$router.onReady(() => {
      this.fetchTiersData();
    });
  },
  methods: {
    /**
     * Récupère les donées du Tiers associé à la demande de PJ (à partir de son jeton)
     */
    fetchTiersData() {
      this.token = this.$route.params.token;
      if (!this.token) {
        this.$router.push("404");
        return;
      }

      axios
        .get(`/api-pj/tiers/${this.token}`)
        .then((res) => {
          this.tiers = res.data.data;

          // Si toutes les PJ ont déjà été transmises
          if (this.tiers.piecesJustificatives.length == 0) {
            this.confirmation.show = true;
            this.confirmation.title = "Documents déjà transmis";
            this.confirmation.message =
              "Toutes vos pièces justificatives ont déjà été téléversées, merci !";
          }

          // Création des dropzones
          for (const pieceJustificative of this.tiers.piecesJustificatives) {
            this.dropzones.push({
              label: pieceJustificative.libelle,
              filename: pieceJustificative.type,
              dzOptions: {
                renameFile: () => {
                  return pieceJustificative.type;
                },
                url: `${ process.env.VUE_APP_API_URL }/api-pj/tiers/${this.token}/piece-justificative`, // API URL
                autoProcessQueue: true,
                paramName: "file", // The name that will be used to transfer the file
                addRemoveLinks: true,
                maxFilesize: 10, // MB
                acceptedFiles:
                  "image/jpeg,image/png,image/tiff,image/x-tiff,application/pdf",
                maxFiles: 1,
                dictRemoveFile: "Supprimer",
                dictFallbackMessage: "Navigateur non supporté",
                dictFileTooBig: "Fichier trop lourd",
                dictInvalidFileType: "Fichier invalide",
                dictResponseError: "Une erreur est survenue",
                dictCancelUpload: "Annuler mise en ligne",
                dictUploadCanceled: "Mise en ligne annulée",
                dictUploadCanceled: "Mise en ligne annulée",
                dictMaxFilesExceeded: "Un seul fichier autorisé",
              },
              required: pieceJustificative.requis,
              uploaded: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$router.push("404");
          return;
        });
    },

    /**
     * Indique dans les données du composant que le fichier 'filename' a été téléversé.
     *
     * @param {String} filename Fichier à marquer comme uploadé
     */
    markFileUploaded(filename) {
      for (const f of this.dropzones) {
        if (f.filename === filename) {
          f.uploaded = true;
          break;
        }
      }
    },

    /**
     * Vérifie si tous les fichiers requis ont déjà été téléversés.
     *
     * @return {Boolean} True si tous les fichiers requis ont été téléversés, false sinon
     */
    areAllRequiredFilesUploaded() {
      for (const f of this.dropzones) {
        if (f.required && !f.uploaded) {
          return false;
        }
      }
      return true;
    },

    /**
     * Met à jour l'état du bouton de validation des pièces justificatives :
     * bouton désactivé tant que toutes les pièces justificatives requises n'ont
     * pas été téléversées.
     */
    refreshSubmitButton() {
      if (this.areAllRequiredFilesUploaded()) {
        this.submitBtnDisabled = false;
      } else {
        this.submitBtnDisabled = true;
      }
    },

    /**
     * Méthode appelée dès qu'un fichier a été téléversé avec succès dans une dropzone
     *
     * @param {Object} file Fichier qui a été téléversé avec succès
     * @param {Object} response Réponse du serveur suite au téléversement
     *
     */
    uploadSuccess(file, response) {
      const uploadedFilename = file?.upload?.filename;
      this.markFileUploaded(uploadedFilename);

      // areAllRequiredFilesUploaded();
      this.refreshSubmitButton();

      console.log("File Successfully Uploaded with file name: " + response.key);
      this.fileName = response.key;
    },

    /**
     * Méthode appelée dès qu'une erreur survient lors du téléversement d'un fichier dans une dropzone
     *
     * @param {Object} file Fichier qui a été téléversé et ayant rencontré une erreur
     * @param {Object} message Message d'erreur (i.e. réponse du serveur)
     */
    uploadError(file, message) {
      console.log("An Error Occurred");
    },

    /**
     * Méthode appelée dès qu'un fichier est retiré d'une dropzone.
     */
    fileRemoved() {},

    /**
     * Méthode appelée lorsque le formulaire est validé (i.e. clic sur bouton "Envoyer")
     */
    processForm() {
      this.error = "";
      this.submitBtnLoading = true;

      axios
        .post(`/api-pj/tiers/${this.token}/confirmation`)
        .then((res) => {
          this.submitBtnLoading = false;
          this.confirmation.show = true;
          this.successPopup(
            this.confirmation.title,
            res.msg || this.confirmation.message
          );
        })
        .catch((err) => {
          console.log(err);
          if (err.msg) {
            this.errorPopup("Erreur", err.msg);
          } else {
            this.errorPopup(
              "Erreur",
              "Une erreur est survenue, merci de réessayer ultérieurement."
            );
          }
          this.submitBtnLoading = false;
        });
    },

    // success
    successPopup(title, msg) {
      this.$swal({
        title: title,
        text: msg,
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    // error
    errorPopup(title, msg) {
      this.$swal({
        title: title,
        text: msg,
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
  },
};
</script>

<style>
.dropzone {
  min-height: auto;
  border: 1px solid #343a40;
  border-radius: 4px;
  margin-bottom: 14px;
  width: 100%;
  height: 140px;
  padding: 0;
  position: relative;
  background: transparent;
}
.dropzone .dropzone-add {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2em;
}
.dropzone .dz-preview {
  margin: 0;
  width: 100%;
  height: 100%;
}
.dropzone .dz-preview .dz-success-mark svg path {
    fill: #1F912A;
}
.dropzone .dz-preview .dz-error-mark svg path {
    fill: #E21D3B;
}
.dropzone .dz-image img {
  width: 100%;
}
.dropzone .dz-preview .dz-details {
  padding: 0.8em 0.4em;
  background-color: rgba(31, 67, 144, 0.8);
}
.dropzone .dz-preview .dz-details .dz-size {
  margin-bottom: 0;
}

.spinner-border {
  vertical-align: middle !important;
}

.headline-entite h2,
.headline-entite h3,
.headline-entite h4,
.headline-entite p {
  margin: 0;
}
.headline-entite {
  margin: 0 0 44px;
  padding: 0 0 22px;
  border-bottom: 1px solid #dfdfe0;
}
.icon-entite {
  background: #a8bef0;
  border-radius: 100%;
  padding: 10px;
  font-size: 20px;
}
.headline-entite .fas {
  color: #1f4391;
}

.btn-submit-files {
  width: 100%;
  background: #1f4390;
}

.required {
  color: #e21d3b;
}
.page-title {
  font-weight: 800;
  font-family: "Source Sans Pro";
  margin: 40px 0 80px;
  font-size: 21px;
}

.footer a,
.footer a:hover {
  color: #f4f6f9;
  font-weight: 800;
  font-size: 16px;
}
.subtitle {
  color: #f4f6f9;
  margin: 28px 0;
  font-weight: 800;
  font-size: 16px;
}
.bar-gradient {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -2px;
  background: #f4f6f9;
  height: 2px;
  width: 22%;
  max-width: 140px;
}
.bar-gradient-left {
  margin-right: 22px;
  background: rgb(31, 67, 145);
  background: linear-gradient(
    90deg,
    rgba(31, 67, 145, 0) 0%,
    rgba(244, 246, 249, 1) 100%
  );
}
.bar-gradient-right {
  margin-left: 22px;
  background: rgb(31, 67, 145);
  background: linear-gradient(
    90deg,
    rgba(244, 246, 249, 1) 0%,
    rgba(31, 67, 145, 0) 100%
  );
}
</style>
