<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{title}}
        </h2>
        <p class="mb-2">
          {{message}}
        </p>

        <!-- image -->
        <b-img
          fluid
          src="@/assets/images/commissaires-de-justice-bleu.jpg"
          alt="Commissaires de Justice"
        />
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  props: {
    title: {
      default: 'Documents envoyés'
    },
    message: {
      default: 'Vos pièces justificatives ont bien été envoyées, merci !'
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
